import _ from 'lodash'
import { getETesting, getETestingAnswer } from 'src/services/eTesting'
import { resetAnswer, setSectionResult } from 'src/redux/slices/eTesting'
import {
  startLessonLoading,
  stopLessonLoading,
} from 'src/redux/slices/eLearning/learning'
import { getLearningState } from '../../../events'

export const fetchETesting = (props) => async (dispatch) => {
  const { payload, isReview, testRound } = props
  dispatch(startLessonLoading())
  if (isReview) dispatch(setSectionResult())
  if (testRound > 1) dispatch(resetAnswer())
  await dispatch(getETesting(payload))
  dispatch(stopLessonLoading())
}

export const fetchELearningETestingAnswer = () => async (dispatch) => {
  const { eLearningCourseBatchLearner, selectedLesson } = getLearningState()
  const uuid = _.get(selectedLesson, 'eTestingUuid', '')
  const eLearningModule = _.get(selectedLesson, 'eLearningModule', {})
  const payload = {
    isModule: true,
    uuid,
    eLearningCourseBatchLearner,
    eLearningModule,
  }
  await dispatch(getETestingAnswer(payload))
}
