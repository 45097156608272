import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import Link from '@mui/material/Link'
import { store } from 'src/App'
import { getQueryParams } from 'src/utils/lib'
import { path } from 'src/constants/path'
import { getEConfiguration } from 'src/services/eConfiguration'
import { getBatchDetail } from 'src/services/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from 'src/redux/slices/alertDialog'
import {
  setChangeCamera,
  openDialog as openEnrollDialog,
  closeDialog as closeEnrollDialog,
  setStep,
  setTime,
  setFailMaxTimes,
} from 'src/redux/slices/eLearning/enroll'
import { getCheckFailScanCount } from 'src/services/eLearning/enroll'
import { dialogContentObj } from 'src/modules/ELearning/components/DialogContent/model'
import { handleOnLogout } from 'src/components/Appbar/AppMenu/events'
import DisallowDialog from './DisallowDialog'

export const handleInitVerify = (uuid, auth) => {
  store.dispatch(fetchBatchDetail(uuid, auth))
  store.dispatch(setStep(2))
}

export const fetchBatchDetail = (uuid, auth) => async (dispatch) => {
  dispatch(getEConfiguration())
  dispatch(startLoading())
  const batchLearnerUuid = getQueryParams('learner')
  const response = await dispatch(getBatchDetail({ uuid, batchLearnerUuid }))
  dispatch(stopLoading())

  if (_.isEmpty(response.error)) {
    const courseUuid = _.get(response.payload, 'data.eLearningCourse.uuid', '')
    await dispatch(handleCheckAllowScan(courseUuid))
  } else {
    const error = _.get(response, 'meta.response', {})
    if (error.status === 404) {
      store.dispatch(
        openDialog({
          ...dialogContentObj,
          title: 'ไม่สามารถเข้าถึงหน้ายืนยันตัวตนได้',
          message: [
            'กรุณาเข้าสู่ระบบด้วยบัญชี (Username) ที่ท่านลงทะเบียนเรียน',
          ],
          buttonLeft: {
            label: 'ยกเลิก',
            handleClick: () => {
              store.dispatch(closeDialog())
              window.location.href = path.myClass
            },
          },
          buttonRight: {
            label: 'เข้าสู่ระบบอีกครั้ง',
            handleClick: () => {
              const type = localStorage.getItem('type')
              store.dispatch(handleOnLogout(auth, type))
            },
          },
        })
      )
    }
  }
}

export const handleCheckAllowScan = (uuid) => async (dispatch) => {
  if (_.isEmpty(uuid)) return
  const batchLearnerUuid = getQueryParams('learner')
  const response = await dispatch(
    getCheckFailScanCount({ batchLearnerUuid, uuid })
  )
  if (_.isEmpty(response.error)) {
    const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
    if (!_.isEmpty(expireDateTime)) {
      dispatch(handleDisallowScan(response))
      dispatch(
        openEnrollDialog({
          content: <DisallowDialog />,
          canClickOutside: false,
        })
      )
    }
  } else {
    const error = _.get(response, 'meta.response.data.response.constraints', {})
    dispatch(
      openErrorDialog({
        ...error,
        message: error?.value,
        buttonRight: {
          handleClick: () =>
            (window.location.href = `${path.eLearningCourse}/${uuid}`),
        },
      })
    )
  }
}

export const handleDisallowScan = (response) => (dispatch) => {
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  const time = handleCalculateTimeLeft(expireDateTime)
  dispatch(setTime(time))
  const failTimes = _.get(response.payload.data, 'countRegister', '')
  dispatch(setFailMaxTimes(failTimes))
}

export const handleCalculateTimeLeft = (date) => {
  const dateTime =
    dayjs(new Date(date)).utc().format('MM/DD/YYYY') +
    ' ' +
    dayjs(new Date(date)).utc().format('HH:mm:ss')
  const expireDate = dayjs(dateTime)
  const current = dayjs(new Date())
  const timeRemain = expireDate.diff(current, 'second')
  const minutes = Math.floor(timeRemain / 60)
  const mins = minutes
  const secs = timeRemain - minutes * 60
  return { mins, secs }
}

export const handleOpenCamera = (field) => (dispatch) => {
  dispatch(setChangeCamera({ key: 'isOpen', value: true }))
  dispatch(setChangeCamera({ key: field, value: null }))
}

export const openCameraErrorDialog = () => {
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      title: 'ไม่สามารถเปิดกล้องได้',
      message: [
        <>
          กรุณาตรวจสอบการตั้งค่าอุปกรณ์ของท่าน{' '}
          <Link fontWeight={700}>วิธีการตั้งค่า</Link>
        </>,
      ],
      buttonRight: {
        handleClick: () => {
          store.dispatch(closeDialog())
          store.dispatch(closeEnrollDialog())
        },
      },
    })
  )
}
