import React, { useState, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material'
import ScrollFixedTopContainer from 'src/components/ScrollFixedTopContainer'
import Search from 'src/components/Search'
import { checkOnFilter } from 'src/components/Filter/events'
import FilterButton from '../../../components/FilterButton'
import { BackButton } from '../../../components'
import FilterDrawer from '../Filter/FilterDrawer'
import { StyledListHeader, StyledRightFlex } from './Styled'
import {
  setFilterValue,
  handleSearch,
} from 'src/redux/slices/eLearning/collection'

const ListHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const { filter, name } = useSelector(
    (state) => ({
      filter: state.eLearningCollection.filter,
      name: state.eLearningCollection.filter.name,
    }),
    shallowEqual
  )
  const [isOpen, setOpen] = useState(false)
  const isOnFilter = useMemo(() => checkOnFilter(filter), [filter])
  return (
    <ScrollFixedTopContainer
      sx={{ backgroundColor: theme.palette?.background?.blue }}
    >
      <StyledListHeader>
        <BackButton onClick={() => history.push('/')} />

        <StyledRightFlex>
          <Search
            boxSx={{ width: 302 }}
            placeholder="ค้นหาชื่อ Collection"
            text={name}
            setText={(e) => dispatch(setFilterValue({ key: 'name', value: e }))}
            handleSearch={() => dispatch(handleSearch())}
          />
          <FilterButton isOnFilter={isOnFilter} onClick={() => setOpen(true)} />
        </StyledRightFlex>
      </StyledListHeader>
      <FilterDrawer isOpen={isOpen} setOpen={setOpen} />
    </ScrollFixedTopContainer>
  )
}
export default ListHeader
