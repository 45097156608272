import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'
import { boxSize } from './model'

export const StyledColumn = styled(Column)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: { width: 548, margin: 'auto' },
  [theme.breakpoints.down('sm')]: { width: 294 },
}))

export const StyledHeadBox = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: 78, height: 78 },
  [theme.breakpoints.down('sm')]: { minWidth: 'calc(302px / 7)', height: 43 },
}))

export const StyledEventBox = styled(Column)(({ theme }) => ({
  width: boxSize.lg,
  height: boxSize.lg,
  border: '1px solid',
  borderColor: theme.palette?.primary?.main,
  background: theme.palette?.background?.paper,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: { width: boxSize.md, height: boxSize.md },
  [theme.breakpoints.down('sm')]: { width: boxSize.sm, height: boxSize.sm },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  borderColor: theme.palette?.blue?.blueLine,
}))
