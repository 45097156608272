export const MONTH_TH = {
  January: 'มกราคม',
  February: 'กุมภาพันธ์',
  March: 'มีนาคม',
  April: 'เมษายน',
  May: 'พฤษภาคม',
  June: 'มิถุนายน',
  July: 'กรกฎาคม',
  August: 'สิงหาคม',
  September: 'กันยายน',
  October: 'ตุลาคม',
  November: 'พฤศจิกายน',
  December: 'ธันวาคม',
}

export const dayThList = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']

export const boxSize = { lg: 64, md: 78, sm: 43 }
