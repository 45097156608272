import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  COURSE_TYPE,
  E_LEARNING_LEARNER_RESULT,
  E_LEARNING_LEARNER_STATUS,
  E_LEARNING_STATUS,
} from '../../../constants/eLearning'
import { StyledContainer } from '../../../components/Layout/Styled'
import Breadcrumb from '../../../components/Breadcrumb'
import Template from '../../../components/Template'
import QuestionDisplay from '../../ELearning/components/QuestionDisplay'
import { listBreadcrumbs } from '../RegistrationDetail/model'
import ClassStatus from '../RegistrationDetail/ClassStatus'
import Header from '../Header'
import { StyledProgressCard } from './ClassDetailCard/ClassProgress/Styled'
import LearnerResult from './components/LearnerResult'
import { fetchDataRegistration, LayoutPreview, onDownload } from './events'
import ClassContent from './ClassContent'
import AttendanceHistory from './AttendanceHistory'
import ClassDetailCard from './ClassDetailCard'
import {
  CertContainer,
  CertDetailContainer,
  StyledCardBoxPreview,
} from './Styled'

const ELearningRegistrationDetail = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const eLearningCourseBatchLearner = _.get(
    data,
    'eLearningCourseBatchLearner',
    {}
  )
  const courseType = _.get(
    eLearningCourseBatchLearner,
    'eLearningCourse.courseType',
    ''
  )
  const questions = _.get(data, 'questions', [])
  const learnerResult = _.get(eLearningCourseBatchLearner, 'learnerResult', '')
  const isHaveCert = _.get(data, 'isHaveCert', false)
  const isEligible =
    _.get(data, 'classLearnerStatus', '') ===
    E_LEARNING_LEARNER_STATUS.ELIGIBLE_ANNOUNCE

  useEffect(() => {
    dispatch(fetchDataRegistration(uuid))
  }, [uuid])

  return (
    <Template>
      <StyledContainer>
        <Breadcrumb list={listBreadcrumbs} />
        <Header isELearning />
        {_.isEmpty(learnerResult) ? (
          <ClassStatus isELearning />
        ) : (
          <LearnerResult />
        )}
        <ClassDetailCard />
        {isEligible && isHaveCert && <CertificateContent />}
        <ClassContent />
        <AttendanceHistory />
        {courseType === COURSE_TYPE.OIC && (
          <QuestionDisplay questions={questions} />
        )}
      </StyledContainer>
    </Template>
  )
}

export default ELearningRegistrationDetail

export const CertificateContent = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const eLearningCourseBatchLearner = _.get(
    data,
    'eLearningCourseBatchLearner',
    {}
  )
  const batchStatus = _.get(
    eLearningCourseBatchLearner,
    'eLearningCourseBatch.status',
    ''
  )
  const isClassComplete =
    _.isEmpty(batchStatus) || batchStatus === E_LEARNING_STATUS.COMPLETED

  if (isClassComplete) {
    const learnerResult = _.get(
      eLearningCourseBatchLearner,
      'learnerResult',
      ''
    )
    const isPass =
      learnerResult === E_LEARNING_LEARNER_RESULT.COMPLETE ||
      learnerResult === E_LEARNING_LEARNER_RESULT.PASS

    if (isPass) {
      const eCertification = _.get(data, 'eCertificationVersion', '')
      const eCertExpireDate = _.get(data, 'expireCertDate', '')
      const isCanDownloadCert = _.get(data, 'isCanDownloadCert', false)
      const eCertText = isCanDownloadCert
        ? 'ดาวน์โหลดประกาศนียบัตร'
        : 'หมดอายุแล้ว'
      return (
        <CertContainer>
          <StyledCardBoxPreview>
            <LayoutPreview version={eCertification} />
          </StyledCardBoxPreview>
          <CertDetailContainer>
            <Typography variant="h6" color="primary">
              ยินดีด้วย! คุณได้รับประกาศนียบัตร
            </Typography>
            <Button
              startIcon={<CloudDownloadIcon />}
              variant="contained"
              disabled={!isCanDownloadCert}
              onClick={() => dispatch(onDownload(uuid))}
            >
              {eCertText}
            </Button>
            {eCertExpireDate && (
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ margin: '0px auto' }}
              >
                หมดอายุ: {eCertExpireDate}
              </Typography>
            )}
          </CertDetailContainer>
        </CertContainer>
      )
    }

    return <></>
  }

  return (
    <StyledProgressCard sx={{ p: 3, height: 134, justifyContent: 'center' }}>
      <Typography variant="h6" color="primary" textAlign="center">
        คุณจะได้รับใบประกาศนียบัตรหลังจากเจ้าหน้าที่ยืนยันผลอบรมเรียบร้อยแล้ว
      </Typography>
    </StyledProgressCard>
  )
}
