import React from 'react'

import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import IconButton from '@mui/material/IconButton'

const ContainedButton = () => {
  const history = useHistory()
  return (
    <IconButton
      sx={{ px: '22px', width: '22px' }}
      size="s"
      data-testid="btn-class"
      onClick={() => history.go(-1)}
    >
      <ArrowBackIcon />
    </IconButton>
  )
}
export default ContainedButton
