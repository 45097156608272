import React, { useCallback, useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReactPlayerLazy from 'react-player/lazy'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { startVideoLoading } from 'src/redux/slices/eLearning/learning'
import CircularContent from '../../../components/CircularContent'
import { handlePlayPause } from './CustomControl/events'
import CustomControl from './CustomControl'
import {
  handleWindowBlur,
  handleVideoReady,
  progressHandler,
  handleEnded,
  handleOrientation,
} from './events'
import {
  ReactPlayerStyle,
  ResponsiveWrapper,
  StyledFloadtingButton,
  reactPlayerConfig,
} from './Styled'

const ReactPlayer = ({ url }) => {
  const wrapperRef = useRef(null)
  const videoPlayerRef = useRef(null)
  const dispatch = useDispatch()
  const { selectedLesson, isVideoLoading } = useSelector(
    (state) => ({
      selectedLesson: state.eLearningLearning.selectedLesson,
      isVideoLoading: state.eLearningLearning.isVideoLoading,
    }),
    shallowEqual
  )
  const videoState = _.get(selectedLesson, 'videoState', {})
  const { playing, playbackRate, muted, volume } = videoState

  const onReady = useCallback(
    () => dispatch(handleVideoReady(videoPlayerRef)),
    [videoPlayerRef.current]
  )

  useEffect(() => {
    dispatch(startVideoLoading())
  }, [])

  useEffect(() => {
    dispatch(handleWindowBlur())
  }, [videoState])

  useEffect(() => {
    dispatch(handleOrientation())
  }, [])

  if (videoState.isForceDisconnect) return <ResponsiveWrapper />

  return (
    <ResponsiveWrapper ref={wrapperRef}>
      <CircularContent color="white" isLoading={isVideoLoading} />

      {!playing && !isVideoLoading && (
        <StyledFloadtingButton onClick={() => dispatch(handlePlayPause(true))}>
          <PlayArrow />
        </StyledFloadtingButton>
      )}

      <CustomControl
        url={url}
        videoPlayerRef={videoPlayerRef}
        wrapperRef={wrapperRef}
      />
      <ReactPlayerLazy
        playsinline
        width="100%"
        height="100%"
        ref={videoPlayerRef}
        url={url}
        playing={playing}
        playbackRate={playbackRate}
        muted={muted}
        volume={volume}
        style={ReactPlayerStyle}
        config={reactPlayerConfig}
        onReady={onReady}
        onEnded={() => dispatch(handleEnded())}
        onProgress={(state) => dispatch(progressHandler(state))}
      />
    </ResponsiveWrapper>
  )
}

export default ReactPlayer
