import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getCourseBatch,
  postEnrollIdCard,
  postFailScanCount,
  getCheckFailScanCount,
  getBatchDetail,
} from 'src/services/eLearning/enroll'
import { initialState } from './model'

const eLearningEnroll = createSlice({
  name: 'eLearningEnroll',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setStep: (state, { payload }) => {
      state.activeStep = payload
    },
    toggleFlag: (state) => {
      state.flag = !state.flag
    },
    setHeight: (state, { payload }) => {
      state.height = payload || 0
    },
    setMonth: (state, { payload }) => {
      state.month = payload
    },
    setChangeBody: (state, { payload }) => {
      const { key, value } = payload
      state.body[key] = value
    },
    setBatch: (state, { payload }) => {
      if (_.isNil(payload)) state.body.batch = null
      state.body.batch = state.batches.find((batch) => batch.uuid === payload)
    },
    setDisableSubmit: (state, { payload }) => {
      state.isDisableSubmit = !payload
    },
    setChangeCamera: (state, { payload }) => {
      const { key, value } = payload
      state.camera[key] = value
    },
    openDialog: (state, { payload }) => {
      state.dialog.isOpen = true
      state.dialog.canClickOutside = _.get(payload, 'canClickOutside', true)
      state.dialog.content = _.get(payload, 'content', null)
    },
    closeDialog: (state) => {
      state.dialog.isOpen = false
    },
    setTime: (state, { payload }) => {
      state.time.mins = _.get(payload, 'mins', 0)
      state.time.secs = _.get(payload, 'secs', 0)
    },
    setFailMaxTimes: (state, { payload }) => {
      state.failMaxTimes = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseBatch.fulfilled, (state, { payload }) => {
        state.data = payload.data.eLearningCourse
        state.batches = payload.data.batches || []
      })
      .addCase(getCourseBatch.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postEnrollIdCard.fulfilled, (state, { payload }) => {
        state.eLearningIdCardEnroll = payload.data
      })
      .addCase(postEnrollIdCard.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getCheckFailScanCount.fulfilled, (state, { payload }) => {
        state.failLogUuid = _.get(payload.data, 'uuid', null)
      })
      .addCase(getCheckFailScanCount.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postFailScanCount.fulfilled, (state, { payload }) => {
        state.failLogUuid = _.get(payload.data, 'uuid', null)
      })
      .addCase(postFailScanCount.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getBatchDetail.fulfilled, (state, { payload }) => {
        state.data = payload.data.eLearningCourse
        state.body.batch = payload.data.batche
      })
      .addCase(getBatchDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setStep,
  toggleFlag,
  setHeight,
  setMonth,
  setChangeBody,
  setBatch,
  setDisableSubmit,
  setChangeCamera,
  openDialog,
  closeDialog,
  setTime,
  setFailMaxTimes,
} = eLearningEnroll.actions

export default eLearningEnroll.reducer
