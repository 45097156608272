import React from 'react'
import _ from 'lodash'
import { store } from 'src/App'
import { getQueryParams } from 'src/utils/lib'
import {
  openDialog as openEnrollDialog,
  closeDialog as closeEnrollDialog,
  setChangeBody,
  setChangeCamera,
  setDisableSubmit,
  setStep,
} from 'src/redux/slices/eLearning/enroll'
import {
  postEnrollRekognition,
  postFailScanCount,
  postVerifyFace,
  putBatchLearnerStatus,
  putFailScanCount,
} from 'src/services/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import {
  ENROLL_REKOGNITION_STATUS,
  REKOGNITION_EVENT,
} from 'src/constants/eLearning'
import { ERROR_OBJ, ERROR_TYPE } from 'src/components/FaceLiveness/model'
import DialogContent from 'src/modules/ELearning/components/DialogContent'
import { dialogContentObj } from 'src/modules/ELearning/components/DialogContent/model'
import { getEnrollState } from '../../events'
import { handleDisallowScan, openCameraErrorDialog } from '../events'
import CameraDialog from './CameraDialog'
import ErrorListContent from './ErrorListContent'
import FinalErrorContent from './FinalErrorContent'

export const handleOpenCameraDialog = () => {
  store.dispatch(setChangeCamera({ key: 'isOpen', value: false }))
  store.dispatch(openEnrollDialog({ content: <CameraDialog /> }))
}

export const handleDetectFace = (livenessData) => async (dispatch) => {
  const idCardImage = _.get(getEnrollState().body, 'idCardImage', '')
  const payload = {
    sessionId: livenessData.sessionId,
    body: { targetImage: idCardImage },
  }
  dispatch(startLoading())
  const response = await dispatch(postVerifyFace(payload))

  if (_.isEmpty(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const status = _.get(data, 'status', '')
    const resEnroll = await handleEnrollRekog(data)
    dispatch(stopLoading())

    if (
      _.isEmpty(resEnroll.error) &&
      status === ENROLL_REKOGNITION_STATUS.SUCCESS
    ) {
      handleVerifySuccess(data)
    } else {
      dispatch(handleVerifyFail(ERROR_TYPE.VERIFY, data))
    }
  } else {
    dispatch(stopLoading())
    const errorKey = response.meta.response.data.constraints.key
    const errorType =
      errorKey === ERROR_TYPE.DETECT ? ERROR_TYPE.DETECT : ERROR_TYPE.SYSTEM
    dispatch(handleVerifyFail(errorType, payload))
  }
}

export const handleEnrollRekog = async (data) => {
  const { body, eLearningIdCardEnroll } = getEnrollState()
  const status = _.get(data, 'status', '')
  const isFaceNotMatch = _.get(data, 'isFaceNotMatch', false)
  const isSend = status === ENROLL_REKOGNITION_STATUS.SUCCESS || isFaceNotMatch
  const learnerUuid = getQueryParams('learner')
  const payload = {
    eLearningCourseBatch: body.batch,
    eLearningCourseBatchLearner: { uuid: learnerUuid },
    eLearningIdCardEnroll: eLearningIdCardEnroll,
    event: REKOGNITION_EVENT.ENROLL,
    faceImageKey: _.get(data, 'faceImageKey', ''),
    percent: _.get(data, 'percent', 0),
    times: 1,
    isFaceNotMatch,
    status,
    isSend,
  }

  return await store.dispatch(postEnrollRekognition(payload))
}

export const handleVerifySuccess = async (data) => {
  const { failLogUuid } = getEnrollState()
  const faceImageKey = _.get(data, 'faceImageKey', '')
  store.dispatch(setChangeBody({ key: 'faceImage', value: faceImageKey }))
  store.dispatch(setDisableSubmit(true))
  store.dispatch(closeEnrollDialog())

  const updatePayload = { uuid: failLogUuid, checkStatus: 'pass' }
  await store.dispatch(putFailScanCount(updatePayload))

  const batchLearnerUuid = getQueryParams('learner')
  const response = await store.dispatch(putBatchLearnerStatus(batchLearnerUuid))

  if (_.isEmpty(response.error)) {
    store.dispatch(
      openDialog({
        ...dialogContentObj,
        iconType: 'done',
        title: 'ยืนยันตัวตนสำเร็จ',
        buttonRight: {
          handleClick: () => {
            window.scrollTo(0, 0)
            store.dispatch(setStep(3))
            store.dispatch(closeDialog())
          },
        },
      })
    )
  }
}

export const handleVerifyFail = (type, payload) => async (dispatch) => {
  const errorObj = _.get(ERROR_OBJ, type, ERROR_OBJ[ERROR_TYPE.SYSTEM])

  if (type === ERROR_TYPE.CAMERA_ACCESS_ERROR) {
    openCameraErrorDialog()
    return
  }

  if (!errorObj.isCount) {
    openLivenessErrorDialog(errorObj)
    return
  }

  const { data, body, failLogUuid } = getEnrollState()
  dispatch(startLoading())
  let response = {}
  if (_.isEmpty(failLogUuid)) {
    const payload = {
      eLearningCourse: { id: data.id, uuid: data.uuid },
      eLearningCourseBatch: body.batch,
    }
    response = await dispatch(postFailScanCount(payload))
  } else {
    const payload = { uuid: failLogUuid, checkStatus: 'fail' }
    response = await dispatch(putFailScanCount(payload))
  }
  dispatch(stopLoading())
  dispatch(closeEnrollDialog())

  const countRes = _.get(response, 'payload.data', {})
  const countRegister = _.get(countRes, 'countRegister', 0)
  const expireDateTime = _.get(countRes, 'registerBreakTime', '')
  if (_.isEmpty(expireDateTime)) {
    openScanErrorDialog(countRegister)
  } else {
    dispatch(handleDisallowScan(response))
    handleScanFinalError(type, payload, countRegister)
  }
}

export const handleScanFinalError = async (type, payload, countRegister) => {
  let data = {
    status: ENROLL_REKOGNITION_STATUS.SUCCESS,
    isFaceNotMatch: true,
  }

  if (type === ERROR_TYPE.VERIFY) {
    // fail on rekognition step
    data = { ...payload, ...data }
  } else {
    // fail on verify step
    const { failLogUuid } = getEnrollState()
    const response = await store.dispatch(
      postVerifyFace({ ...payload, body: { ...payload.body, failLogUuid } })
    )
    data = {
      ..._.get(response.payload, 'data', {}),
      ...data,
    }
  }

  openScanFinalErrorDialog(data, countRegister)
}

export const openLivenessErrorDialog = (errorObj) => {
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      iconType: 'fail',
      title: errorObj.title,
      message: [errorObj.message],
      buttonRight: {
        handleClick: () => {
          store.dispatch(closeDialog())
          store.dispatch(closeEnrollDialog())
        },
      },
    })
  )
}

export const openScanErrorDialog = (countRegister) => {
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      maxWidth: 'md',
      iconType: 'fail',
      title: 'ยืนยันตัวตนไม่สำเร็จ',
      message: ['อาจเกิดจากสาเหตุดังต่อไปนี้'],
      content: (
        <DialogContent>
          <ErrorListContent />
        </DialogContent>
      ),
      buttonLeft: {
        label: 'ยกเลิก',
        handleClick: () => {
          store.dispatch(closeDialog())
          store.dispatch(closeEnrollDialog())
        },
      },
      buttonRight: {
        label: `ลองอีกครั้ง${
          countRegister ? ` (${Number(countRegister) + 1}/5)` : ''
        }`,
        handleClick: () => {
          store.dispatch(closeDialog())
          handleOpenCameraDialog()
        },
      },
    })
  )
}

export const openScanFinalErrorDialog = (data, countRegister) => {
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      maxWidth: 'md',
      iconType: 'fail',
      title: `ยืนยันตัวตนไม่สำเร็จ (${countRegister}/${countRegister})`,
      message: [
        'ท่านมีความประสงค์จะส่งรูปนี้เข้าระบบเพื่อให้เจ้าหน้าที่พิจารณาเพิ่มเติมหรือไม่',
      ],
      content: (
        <DialogContent>
          <FinalErrorContent data={data} />
        </DialogContent>
      ),
      isHiddenFooter: true,
    })
  )
}
