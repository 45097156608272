import _ from 'lodash'
import { setHeight, toggleFlag } from 'src/redux/slices/eLearning/enroll'
import { boxSize } from '../model'

export const handleChangeMonth = (calendarRef, isNext) => (dispatch) => {
  dispatch(toggleFlag())
  if (calendarRef.current) {
    const calendarApi = calendarRef.current.getApi()
    isNext ? calendarApi.next() : calendarApi.prev()
  }
}

export const handleCalendarHeight = (isLg, isMd) => (dispatch) => {
  const row = document
    .querySelector('.fc-scrollgrid-section-body')
    .querySelector('tbody')
    .querySelectorAll('tr[role="row"]')
  const rowLength = row.length > 6 ? _.round(row.length / 2) : row.length
  const mediaWidth = isLg ? 'lg' : isMd ? 'md' : 'sm'
  const height = rowLength * (boxSize[mediaWidth] + 1)
  dispatch(setHeight(height))
}
