import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledLessonLabel, StyledModuleLabel } from './Styled'
import { MODULE_ICON } from './model'
import { SubTitleContent } from './events'

const ContentTab = () => {
  const data = useSelector((state) => state.eLearningDetail.data, shallowEqual)
  const lessons = _.get(data, 'eLearningCourseLesson', [])
  return (
    <Column>
      {lessons.map((lesson, index) => (
        <LessonLabelWithModule data={lesson} key={index} />
      ))}
    </Column>
  )
}

export default ContentTab

export const LessonLabelWithModule = ({ data }) => {
  const [isShow, setShow] = useState(true)
  const modules = _.get(data, 'eLearningLessonRefModule', [])
  return (
    <>
      <StyledLessonLabel onClick={() => setShow(!isShow)}>
        <Column>
          <StyledTypography
            variant="body1b"
            color="primary"
            sx={{ WebkitLineClamp: '1' }}
          >
            {_.get(data, 'name', '')}
          </StyledTypography>
          <Typography variant="body2" color="text.gray">
            {modules.length} โมดูล
          </Typography>
        </Column>
        <Box sx={{ width: 32, height: 32, svg: { width: 32, height: 32 } }}>
          {isShow ? <KeyboardArrowUpTwoTone /> : <KeyboardArrowDownTwoTone />}
        </Box>
      </StyledLessonLabel>
      {isShow && (
        <Column>
          {modules.map((lesson, index) => (
            <ModuleLabel data={lesson.eLearningModule} key={index} />
          ))}
        </Column>
      )}
    </>
  )
}

export const ModuleLabel = ({ data }) => {
  const type = _.get(data, 'moduleType', '')
  const isLocked = _.get(data, 'isLocked', false)
  const iconPath = isLocked ? 'ic_lock.svg' : MODULE_ICON[type]
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledModuleLabel>
      <Row sx={{ width: 'auto', gap: '10px', img: { width: 20, height: 20 } }}>
        {iconPath && <img src={`/icon/e-learning/${iconPath}`} />}
        <StyledTypography
          variant={isNotSm ? 'body1' : 'body2'}
          sx={{ WebkitLineClamp: '1' }}
        >
          {_.get(data, 'name', '')}
        </StyledTypography>
      </Row>
      <Typography variant="body2b" textAlign="end" minWidth={120}>
        {SubTitleContent(data)}
      </Typography>
    </StyledModuleLabel>
  )
}
