import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { SECTION_OBJ } from '../model'

export const MobileHeader = ({ keySection, total }) => {
  const { listTitle, listUnit } = SECTION_OBJ[keySection]
  const history = useHistory()
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isNotSm) return <></>

  return (
    <Row sx={{ px: 2, py: 3, justifyContent: 'space-between' }}>
      <Button onClick={() => history.push('/')}>
        <ArrowBack />
      </Button>

      <Typography variant="h5">{listTitle}</Typography>

      <Typography variant="body2" color="primary">
        {total} {listUnit}
      </Typography>
    </Row>
  )
}

export const TabletDesktopHeader = ({ keySection, total }) => {
  const { listTitle, listUnit } = SECTION_OBJ[keySection]
  const isNotSm = isMediaWidthMoreThan('sm')

  if (!isNotSm) return <></>

  return (
    <Row sx={{ justifyContent: 'space-between' }}>
      <Typography variant="h6" color="text.primary">
        {listTitle}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {total} {listUnit}
      </Typography>
    </Row>
  )
}

export const BackButton = ({ onClick }) => {
  const isNotSm = isMediaWidthMoreThan('sm')

  if (!isNotSm) return <></>

  return (
    <Button sx={{ minWidth: 112 }} startIcon={<ArrowBack />} onClick={onClick}>
      กลับหน้าหลัก
    </Button>
  )
}
