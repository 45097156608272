import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import { ClassStatusContainer, LabelContact, BoxLabel } from './Styled'
import { mapStatusIcon, mapLabel } from './events'
import { useHistory } from 'react-router-dom'

const ClassStatus = ({ isELearning }) => {
  const history = useHistory()
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const uuid = _.get(data, 'trainingPlanUuid', '')
  const classLearnerUuid = _.get(data, 'classLearnerUuid', '')
  const batchLearner = _.get(data, 'eLearningCourseBatchLearner', {})
  const cStatus = isELearning
    ? _.get(batchLearner, 'status', '')
    : _.get(data, 'classLearnerStatus', '')
  const classStatusEn = _.get(data, 'classStatus', '')
  let classStatus = isELearning
    ? _.get(batchLearner, 'eLearningCourseBatch.status', '') || classStatusEn
    : classStatusEn
  if (isELearning && classStatus === 'CANCEL') classStatus = classStatusEn
  const isClassCancelled =
    classStatus === 'CANCEL_CLASS' || classStatus === 'CLASS_CANCELED'
  const status = isClassCancelled ? classStatus : cStatus
  const objStatus = mapStatusIcon(status)
  const { header, top, bottom } = mapLabel(status, isELearning)

  return (
    <>
      <ClassStatusContainer color={objStatus.colorBg} status={cStatus}>
        <img src={objStatus.icon} />
        {cStatus === 'ELIGIBLE_ANNOUNCE' ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" color="success.main">
              ผ่านการตรวจสอบ
            </Typography>
            <Typography variant="h4" color="success.main">
              คุณได้รับสิทธิ์เข้าอบรม
            </Typography>
          </Box>
        ) : (
          <Typography variant="h4" color={objStatus.colorLabel}>
            {header}
          </Typography>
        )}

        <Box sx={{ textAlign: 'center' }}>
          <Typography color="text.secondary" variant="body2">
            {top}
          </Typography>
          <BoxLabel>
            <Typography color="text.secondary" variant="body2">
              {bottom}
            </Typography>
            {(cStatus === 'WAITING_CHECK' || cStatus === 'WAITING_DOCUMENT') &&
              !isClassCancelled && (
                <LabelContact
                  variant="body1"
                  color="info.main"
                  data-testid="btn-contact"
                  onClick={() => {
                    window.location.href = '/location'
                  }}
                >
                  ติดต่อเจ้าหน้าที่
                </LabelContact>
              )}
          </BoxLabel>
          {cStatus === 'REJECTED_DOCUMENT' && (
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              color="secondary"
              size="l"
              onClick={() =>
                history.push(`/upload-document/${uuid}?id=${classLearnerUuid}`)
              }
            >
              ส่งเอกสารใหม่
            </Button>
          )}
        </Box>
      </ClassStatusContainer>
    </>
  )
}

export default ClassStatus
