import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledQuestionCard } from 'src/components/Preview/QuestionCard/Styled'
import Choice from './Choice'
import QuestionMedia from './QuestionMedia'

const QuestionCard = ({ keyQuestion }) => {
  const question = useSelector(
    (state) => state.eTesting.questions.find((item) => item.key == keyQuestion),
    shallowEqual
  )

  return (
    <StyledQuestionCard variant="question">
      <Question question={question} />
      <QuestionMedia key={keyQuestion} question={question} />
      <Choice keyQuestion={keyQuestion} />
    </StyledQuestionCard>
  )
}

export default QuestionCard

export const Question = ({ question }) => {
  const { sequence, title, questionType } = question
  const maxAnswerNumber = _.get(question, 'maxAnswerNumber', 0)
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
      <Typography variant="body1b">{sequence}.</Typography>
      <Typography sx={{ wordBreak: 'break-word' }} variant="body1b">
        {title}{' '}
        {questionType == 'MULTIPLE' && (
          <span style={{ fontWeight: 400, whiteSpace: 'pre' }}>
            (เลือกตอบ {maxAnswerNumber} ข้อ)
          </span>
        )}
      </Typography>
    </Box>
  )
}
