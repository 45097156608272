export const formatData = (field) => {
  let color = ''
  switch (field) {
    case 'WAITING_CHECK':
    case 'WAITING_DOCUMENT':
      color = 'other.ratingActive'
      break
    case 'CLOSEREGISTRATION':
    case 'WAITING_CLASS_TO_START':
      color = 'text.silver'
      break
    case 'INPROGRESS':
    case 'CLASS_IN_PROGRESS':
      color = 'primary.main'
      break
    case 'OPENREGISTRATION':
    case 'TRAINING_CLOSED_PASS':
    case 'CLASS_CLOSED_PASS':
      color = 'success.main'
      break
    case 'TRAINING_CLOSED_NOT_PASS':
    case 'CLASS_CLOSED_NOT_PASS':
    case 'INELIGIBLE':
    case 'CANCEL':
    case 'CANCELED':
    case 'TIME_OUT':
    default:
      color = 'error.main'
      break
  }
  return color
}
