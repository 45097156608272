export const mapStatusIcon = (status) => {
  switch (status) {
    case 'WAITING_CHECK':
    case 'WAITING_DOCUMENT':
      return {
        icon: '/image/class/detail/waiting.png',
        colorBg: 'warning.background',
        colorLabel: 'warning.main',
      }
    case 'TIME_OUT':
      return {
        icon: '/image/class/detail/error.svg',
        colorBg: 'error.background',
        colorLabel: 'error.main',
      }
    case 'REJECTED_DOCUMENT':
    case 'INELIGIBLE':
      return {
        icon: '/image/class/detail/warning.svg',
        colorBg: 'error.background',
        colorLabel: 'error.main',
      }
    case 'ELIGIBLE_ANNOUNCE':
      return {
        icon: '/image/class/detail/success.svg',
        colorBg: 'success.background',
        colorLabel: 'success.main',
      }
    default:
      return {
        icon: '/image/class/detail/cancel.svg',
        colorBg: 'action.disabledBackground',
        colorLabel: 'text.primary',
      }
  }
}

export const mapLabel = (status) => {
  switch (status) {
    case 'WAITING_CHECK':
      return {
        header: 'ระหว่างการตรวจสอบ',
        top: 'ติดตามผลการสมัครได้ที่นี่ หรือทางอีเมลที่ใช้ลงทะเบียน',
        bottom: 'หากไม่ได้รับการยืนยันกรุณา',
      }
    case 'WAITING_DOCUMENT':
      return {
        header: 'รออัปโหลดเอกสาร',
        top: 'กรุณาส่งเอกสารที่ใช้สำหรับการลงทะเบียนหลักสูตรนี้',
        bottom: 'หากมีคำถามเพิ่มเติมกรุณา',
      }
    case 'TIME_OUT':
      return {
        header: 'หมดเวลาอัปโหลดเอกสาร',
        top: 'ไม่สามารถอัปโหลดเอกสารได้ เนื่องจากเกินระยะเวลาที่กำหนด',
        bottom: 'โปรดสมัครหลักสูตรอื่นที่ยังอยู่ในช่วงเวลาเปิดรับสมัคร',
      }
    case 'REJECTED_DOCUMENT':
      return {
        header: 'เอกสารไม่ผ่านการตรวจสอบ',
        top: 'ภาพถ่ายไม่ชัด อ่านข้อมูลไม่เห็น',
        bottom:
          'โปรดตรวจสอบอีกครั้งก่อนส่งเอกสารใหม่ คุณสามารถส่งได้อีกหนึ่งครั้งมิฉะนั้นจะถูกตัดสิทธิ์เรียน',
      }
    case 'INELIGIBLE':
      return {
        header: 'คุณไม่มีสิทธิ์เข้าอบรม',
        top: 'ไม่ได้รับการยืนยันให้มีสิทธิ์เรียน คุณสมบัติของผู้เรียนไม่ตรงตามเงื่อนไข',
        bottom:
          'หากมีข้อสงสัยโปรดติดต่อสอบถามจากช่องทางการติดต่อในหน้ารายละเอียดของหลักสูตรนี้',
      }
    case 'CANCEL': {
      return {
        header: 'ยกเลิกการสมัครแล้ว',
        top: 'คุณได้ยกเลิกการลงทะเบียนเรียนหลักสูตรนี้แล้ว',
        bottom: 'โปรดตรวจสอบอีเมลยืนยันการยกเลิกที่เราส่งไปอีกครั้ง',
      }
    }
    case 'CANCELED':
      return {
        header: 'ถูกยกเลิกการสมัคร',
        top: 'การลงทะเบียนเรียนหลักสูตรนี้ของคุณถูกยกเลิกแล้ว',
        bottom: 'โปรดตรวจสอบอีเมลยืนยันการยกเลิกที่เราส่งไปอีกครั้ง',
      }
    case 'CANCEL_CLASS':
    case 'CLASS_CANCELED':
      return {
        header: 'ถูกยกเลิกคลาส',
        top: 'การลงทะเบียนเรียนหลักสูตรนี้ของคุณถูกยกเลิกแล้ว',
        bottom: 'โปรดตรวจสอบอีเมลยืนยันการยกเลิกที่เราส่งไปอีกครั้ง',
      }
    default:
      return {}
  }
}
// openStatus ->
// OPENING กำลังเปิด
// WAS_OPEN เปิดล่าสุด
// NOT_OPEN_YET ยังไม่เปิดให้ทำ

// classLearnerStatus->
// WAITING_CHECK รอตรวจสอบ
// WAITING_DOCUMENT รออัปโหลดเอกสาร

// TIME_OUT หมดระยะเวลาอัปโหลด
// REJECTED_DOCUMENT เอกสารไม่ผ่าน
// INELIGIBLE ไม่มีสิทธิ์เข้าเรียน

// CANCEL ยกเลิกโดยคุณ
// CANCELED ยกเลิกโดยแอดมิน

// dayAttendanceStatus->
// null โปรดเช็คชื่อในห้องเรียน
// STUDY มาเรียน
// MISSING ไม่มาเรียน

// sentStatus-> E_TESTING type
// WAITING รอตรวจ
// PASS ผ่าน
// NOT_PASS ไม่ผ่าน
// NOT_SENT ผู้เรียนไม่ส่งข้อสอบ
// WAITING_ASSIGNEE_CALL รอวิทยากรเรียก

// sentStatus-> E_EVA type
// NOT_SENT ทำแล้ว
// SENT ไม่ได้ทำ
